import { useQuery } from '@tanstack/react-query';

import { useHermes } from '@/auth/use-hermes';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { queryKeys } from '@/queries/query-factory';
import { orderItemsApiResponseSchema } from '@/schema/order-item.schema';
import { OrderItem } from '@/schema/order/order-item';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { transformApi } from '@/utils/transform-api';
import { WontFix } from '../../../global';

interface UseOrderItemsProps {
  pageSize?: number;
  pageNumber?: number;
  typeExcludes?: OrderItem['type'][];
}

const itemTypeToNNItemType = {
  cash_redemption_order_item: 'CashRedemptionOrderItem',
  points_transfer_order_item: 'PointsTransferOrderItem',
  crypto_order_item: 'CryptoOrderItem',
  sustainability_order_item: 'SustainabilityOrderItem',
  gift_card_order_item: 'GiftCardOrderItem',
  travel_booking_order_item: 'TravelBookingOrderItem',
  voucher_order_item: 'VoucherOrderItem',
  merchandise_order_item: 'MerchandiseOrderItem',
} as const;

export function useOrderItems({
  pageSize = 3,
  pageNumber = 1,
  typeExcludes,
}: UseOrderItemsProps) {
  const path = `${getClientUrl()}/api/order_items?`;
  const authedFetch = useAuthFetchKy;
  const hermes = useHermes();

  let queryParams = new URLSearchParams();

  if (typeExcludes) {
    const filter_params = typeExcludes
      .map((typeExclude) => itemTypeToNNItemType[typeExclude])
      .join(',');
    queryParams.set('filter[type][$neq]', filter_params);
  }

  queryParams.set('page[number]', pageNumber.toString());
  queryParams.set('page[size]', pageSize.toString());
  queryParams.set('sort', 'created_at');

  return useQuery({
    ...queryKeys.orderItem.list({
      typeExcludes: typeExcludes?.join(',') || '',
      pageSize,
      pageNumber,
    }),
    queryFn: async ({ signal }) => {
      const data = await authedFetch
        .get(`${path}${queryParams}`, { signal })
        .json<WontFix>();

      const transformedData = transformApi(
        {
          ...data,
          meta: data.meta || { count: 0 },
        },
        {
          ignoreNotFoundId: true,
        },
      );

      return validateSchema({
        data: transformedData,
        schemaName: 'order-items-schema',
        zodSchema: orderItemsApiResponseSchema,
      });
    },
    enabled: hermes.loggedIn,
  });
}
