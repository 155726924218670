'use client';
import { MenuItemType } from '@/config-schema/navigation';
import React from 'react';

export function customNavigation(
  item: MenuItemType,
  event: React.MouseEvent<HTMLAnchorElement>,
  setShowRedirectModal: (show: boolean) => void,
  onClick?: () => void,
) {
  if (['shopApple', 'appleMerchandise'].includes(item.itemType)) {
    event.preventDefault();
    setShowRedirectModal(true);
  } else {
    onClick?.();
  }
}
