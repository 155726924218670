'use client';
import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { Icon } from '@/components/icon';
import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { useOrderItems } from '@/hooks/order_items/use-order-items';
import { orderItemTypeToTypeInPath } from '@/schema/order/common';
import {
  cashbackOrderItemSchema,
  cryptoOrderItemSchema,
  giftCardOrderItemSchema,
  merchandiseOrderItemSchema,
  pointsTransferOrderItemSchema,
  sustainabilityOrderItemSchema,
  voucherOrderItemSchema,
} from '@/schema/order/order-item';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { z } from 'zod';
import { RedeemAgainBannerSkeleton } from './redeem-again-banner-skeleton';

// Exclude travel_booking_order_item and merchandise_order_item
const redeemAgainOrderItemSchema = z.union([
  giftCardOrderItemSchema,
  sustainabilityOrderItemSchema,
  cashbackOrderItemSchema,
  pointsTransferOrderItemSchema,
  cryptoOrderItemSchema,
  voucherOrderItemSchema,
  merchandiseOrderItemSchema,
]);

type RedeemAgainOrderItem = z.infer<typeof redeemAgainOrderItemSchema>;

export function RedeemAgainBanner() {
  const { t } = useTranslation();
  const { data, isLoading, isError, isFetched } = useOrderItems({
    typeExcludes: ['travel_booking_order_item', 'merchandise_order_item'],
  });

  if (isLoading) return <RedeemAgainBannerSkeleton />;

  if (isError || !data || !isFetched || data.data.length === 0) {
    return null;
  }

  // TODO(khoi): Update to TypeScript 5.5.4 and remove the type assertion
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const filteredData = data.data.filter(
    (item): item is RedeemAgainOrderItem => {
      return (
        item.type !== 'travel_booking_order_item' &&
        item.type !== 'merchandise_order_item'
      );
    },
  );

  return (
    <HomepageProductsCarousel
      titleComponent={
        <div className="flex items-center justify-start gap-4">
          <p className="text-heading-2">{t('Redeem again')}</p>
          <RcLink
            href={'/rewards-summary/my-rewards'}
            variant={RcLinkVariant.SECONDARY}
            className="text-secondary"
          >
            {t('View all redemptions')}
          </RcLink>
        </div>
      }
      className="gap-6 container-responsive"
      carouselWrapperClassName="lg:grid lg:grid-cols-3 gap-6"
      hideOnNotSlidable
    >
      {filteredData.map((item) => (
        <RcLink
          key={item.id}
          className={cn(
            'relative overflow-hidden',
            'flex w-[330px] shrink-0 items-center justify-start gap-4 lg:w-auto',
            'rounded-custom bg-neutral-200 p-3 pr-5',
          )}
          href={getProductLink(item)}
        >
          <img
            src={item.data.productImageUrl}
            className="h-12 rounded"
            alt={t('redeemed product')}
          />

          <p className="line-clamp-2">{t(item.data.productName)}</p>

          <Icon
            name="clock-rotate-left"
            className="absolute -right-2 ml-auto h-10 w-10 text-neutral-300"
          />
        </RcLink>
      ))}
    </HomepageProductsCarousel>
  );
}

function getProductLink(orderItem: RedeemAgainOrderItem) {
  // NN introduced a new field `originalCardId` referred to the original gift card id from listing endpoint
  // cardId is the denominationId FE used to checkout
  // NN can't backfill the originalCardId for old orders/redemptions so we need to handle both cases

  const productId =
    orderItem.type === 'gift_card_order_item'
      ? orderItem.data.originalCardId || orderItem.data.cardId
      : orderItem.data.loyaltyProgramId;

  return [
    'products',
    orderItemTypeToTypeInPath[orderItem.type],
    productId,
  ].join('/');
}
