'use client';

import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { OfferTypeFilter } from '@/app/[locale]/products/offers/type';
import { useHermes } from '@/auth/use-hermes';
import { Container } from '@/components/container';
import { RcLink } from '@/components/rc-link';
import {
  FeaturedProductsConfig,
  ProductMetaData,
} from '@/config-schema/home-page';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { useGiftCardById } from '@/hooks/gift-cards/use-gift-cards';
import useLoyaltyProgramByTypeAndId, {
  isCashbackLoyaltyProgram,
  isCryptoLoyaltyProgram,
  isFrequentTravelerLoyaltyProgram,
  isSustainabilityLoyaltyProgram,
} from '@/hooks/loyalty-programs/use-loyalty-program-by-type-and-id';
import { useUberCredit } from '@/hooks/loyalty-programs/use-uber-credit';
import { useOfferById } from '@/hooks/offers/use-offer-by-id';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { Link } from '@/i18n/navigation';
import { loyaltyProgramTypeSchema } from '@/schema/loyalty-program.schema';
import { useTranslation } from '@/translation/translation';
import { getCapabilityForLoyaltyProgramType } from '@/utils/get-capability';
import { cn } from '@/utils/tailwind';

type FeaturedProductsProps = Omit<FeaturedProductsConfig, 'type'> & {
  children?: ReactNode;
};

type FeaturedProductProps = Omit<ProductMetaData, 'type'> & {
  key?: string;
  backgroundColor: string;
  readyProduct: () => void;
};

type CardInfoProps = {
  backgroundColor: string;
  image: string;
  imageAlt?: string;
  heading: string;
  subheading?: string;
  points?: number;
};

export function FeaturedProducts(props: FeaturedProductsProps) {
  const { t } = useTranslation();
  const title = props.config.title || t('Featured Redemptions');
  const tileBackgroundColor = props.config.tileBackgroundColor || '#FFFFFF';
  const backgroundColor = props.config.backgroundColor || 'none';

  const { user } = useHermes();

  const [hasProducts, setHasProducts] = useState(false);
  const isGiftCardMaintenance = useFlagsReady('rc_gift_card_maintenance');

  const components = props.data.map((productInfo) => {
    switch (productInfo.productType) {
      case 'gift-card': {
        if (isGiftCardMaintenance) return <></>;
        return (
          <GiftCardTile
            key={productInfo.id}
            {...productInfo}
            readyProduct={() => setHasProducts(true)}
            backgroundColor={tileBackgroundColor}
          />
        );
      }
      case 'points-transfer': {
        return (
          <FrequentTravelerTile
            key={productInfo.id}
            backgroundColor={tileBackgroundColor}
            readyProduct={() => setHasProducts(true)}
            {...productInfo}
          />
        );
      }
      case 'cashback': {
        return (
          <CashbackTile
            key={productInfo.id}
            backgroundColor={tileBackgroundColor}
            readyProduct={() => setHasProducts(true)}
            {...productInfo}
          />
        );
      }
      case 'carbon': {
        return (
          <CarbonTile
            key={productInfo.id}
            backgroundColor={tileBackgroundColor}
            readyProduct={() => setHasProducts(true)}
            {...productInfo}
          />
        );
      }
      case 'crypto': {
        return (
          <CryptoTile
            key={productInfo.id}
            backgroundColor={tileBackgroundColor}
            readyProduct={() => setHasProducts(true)}
            {...productInfo}
          />
        );
      }
      case 'offer': {
        return (
          <OfferTile
            key={productInfo.id}
            backgroundColor={tileBackgroundColor}
            readyProduct={() => setHasProducts(true)}
            {...productInfo}
          />
        );
      }
      case 'uber-credit': {
        return (
          <UberTile
            key={productInfo.id}
            backgroundColor={tileBackgroundColor}
            readyProduct={() => setHasProducts(true)}
            {...productInfo}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  });

  let componentsWithDemo: Array<ReactElement>;

  componentsWithDemo = components;

  // DEMO 1
  //
  // For Seb's User ID, show these specific redemption options
  // British airway
  // United
  // Mandarin Oriental Hotel Group gc
  // Flying Blue
  // Fairmont Hotels & Resorts gc
  if (user && user.sub === 'e1fafe26-1b66-4c82-9828-8ddd7ae7d107') {
    componentsWithDemo = [
      <FrequentTravelerTile
        id="a94793c5-4472-4727-b62f-8db5b35a6d35"
        key="a94793c5-4472-4727-b62f-8db5b35a6d35"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://kaligo.imgix.net/logos/BAAVIOS.en.png',
          alt: '',
        }}
        title={'British Airways'}
        productType={'points-transfer'}
      />,
      <FrequentTravelerTile
        id="580a8f22-7e2b-48dc-96bc-b569c6b1b542"
        key="580a8f22-7e2b-48dc-96bc-b569c6b1b542"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://kaligo.imgix.net/logos/UAMILEAGEPLUS.en.png',
          alt: '',
        }}
        title={'United'}
        productType={'points-transfer'}
      />,
      <GiftCardTile
        id="33002b06-9b91-44b2-8b00-48aad8f686bb"
        key="33002b06-9b91-44b2-8b00-48aad8f686bb"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://catalogue-api.meritincentives.com/media/products/images/Mandarin_Oriental_Hotel_Group.png',
          alt: '',
        }}
        title={'Mandarin Oriental Hotel Group'}
        productType={'gift-card'}
      />,
      <FrequentTravelerTile
        id="bc593c31-7d40-499e-977a-23248eeba307"
        key="bc593c31-7d40-499e-977a-23248eeba307"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://kaligo.imgix.net/logos/AFFLYINGBLUE.en.png',
          alt: '',
        }}
        title={'Flying Blue'}
        productType={'points-transfer'}
      />,
      <GiftCardTile
        id="4879bbec-7c2d-41f7-8d59-b48a734f43df"
        key="4879bbec-7c2d-41f7-8d59-b48a734f43df"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://catalogue-api.meritincentives.com/media/products/images/Fairmont-Hotels_-Resorts_GC.png',
          alt: '',
        }}
        title={'Fairmont Hotels and Resorts'}
        productType={'points-transfer'}
      />,
    ];
  }

  // For Frank's user ID, show this
  // Apple
  // Amazon
  // Airbnb
  // Best Buy
  // Etihad
  if (user && user.sub === '9ac45ec3-509f-4a7c-8d63-b19079c3b823') {
    componentsWithDemo = [
      <GiftCardTile
        id="40dd604a-592a-45b1-9adf-a16d89e3b9ff"
        key="40dd604a-592a-45b1-9adf-a16d89e3b9ff"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://app.giftango.com/GPCGraphics/Apple_B2B_B2C_Design6_CR80_062122_300x190_RGB.png',
          alt: '',
        }}
        title={'Apple'}
        productType={'gift-card'}
      />,
      <GiftCardTile
        id="42c6eba9-059f-4d93-a2ce-c58d8868d4c4"
        key="42c6eba9-059f-4d93-a2ce-c58d8868d4c4"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://d28but9h9ahsdb.cloudfront.net/media/products/images/amazon_GC2.png',
          alt: '',
        }}
        title={'Amazon'}
        productType={'gift-card'}
      />,
      <GiftCardTile
        id="2dc515de-9073-4179-be07-6da12c59fee1"
        key="2dc515de-9073-4179-be07-6da12c59fee1"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://catalogue-api.meritincentives.com/media/products/images/AIRBNB-Gift-Card_1cgrRjd.png',
          alt: '',
        }}
        title={'Airbnb'}
        productType={'gift-card'}
      />,
      <GiftCardTile
        id="4ecf922a-c041-4c8e-b492-b24f615e2e0a"
        key="4ecf922a-c041-4c8e-b492-b24f615e2e0a"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://catalogue-api.meritincentives.com/media/products/images/BESTBUY_GC_xtgnPcw.png',
          alt: '',
        }}
        title={'Best Buy'}
        productType={'gift-card'}
      />,
      <FrequentTravelerTile
        id="5a6edd16-832c-4508-baec-49afaa446d23"
        key="5a6edd16-832c-4508-baec-49afaa446d23"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://kaligo.imgix.net/logos/ETHGUEST.en.png',
          alt: '',
        }}
        title={'Etihad'}
        productType={'points-transfer'}
      />,
    ];
  }

  // DEMO 2
  //
  // For Seb's User ID, show these specific redemption options
  // British Airways TC
  // Uber GC
  // CLO: 5 Points per $1 spent at St Tropez West Village
  // Lululemon GC
  // AMC Theatres GC
  if (user && user.sub === 'b7626380-6839-4914-9767-e40753073f5c') {
    componentsWithDemo = [
      <FrequentTravelerTile
        id="c6981423-efd3-4d22-8335-b403e4de432c"
        key="c6981423-efd3-4d22-8335-b403e4de432c"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://kaligo.imgix.net/logos/BAAVIOS.en.png',
          alt: '',
        }}
        title={'British Airways'}
        productType={'points-transfer'}
      />,

      <GiftCardTile
        id="704bc9a4-9f8c-4bed-b6b0-e29c00a8925f"
        key="704bc9a4-9f8c-4bed-b6b0-e29c00a8925f"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://catalogue-api.meritincentives.com/media/products/images/Uber_GC_USA.png',
          alt: '',
        }}
        title={'Uber'}
        productType={'gift-card'}
      />,
      <OfferTile
        id="956c4a97-9bba-4220-885e-8d99191da65f"
        key="956c4a97-9bba-4220-885e-8d99191da65f"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://d2oexzqukiqsxx.cloudfront.net/qj3qkgrqwrgt7esmcm3ci2fzlm.jpg',
          alt: '',
        }}
        title={'5 Points per $1 spent at St Tropez West Village'}
        productType={'offer'}
      />,
      <GiftCardTile
        id="d483d108-8d5d-4a79-aebd-e0660560dc21"
        key="d483d108-8d5d-4a79-aebd-e0660560dc21"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://catalogue-api.meritincentives.com/media/products/images/Lululemon.png',
          alt: '',
        }}
        title={'Lululemon'}
        productType={'gift-card'}
      />,
      <GiftCardTile
        id="420933d5-a7d2-4b96-b042-d020ab44de99"
        key="420933d5-a7d2-4b96-b042-d020ab44de99"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://app.giftango.com/GPCGraphics/AMC_NoDenom_DIG_CR80_062618_300x190_RGB.png',
          alt: '',
        }}
        title={'AMC Theatres'}
        productType={'gift-card'}
      />,
    ];
  }

  // For Frank's user ID, show this
  // Flying Blue TC
  // Walmart GC
  // Starbucks GC
  // Home Depot GC
  // Accor TC
  if (user && user.sub === '5644ebb0-d58e-48e1-9bfc-383929a61b31') {
    componentsWithDemo = [
      <FrequentTravelerTile
        id="f14a840b-87bf-40a6-86f1-8257470724e9"
        key="5a6edd16-832c-4508-baec-49afaa446d23"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://kaligo.imgix.net/logos/AFFLYINGBLUE.en.png',
          alt: '',
        }}
        title={'Flying Blue'}
        productType={'points-transfer'}
      />,
      <GiftCardTile
        id="100ac6c6-6510-485d-9158-e9fad06d0c49"
        key="100ac6c6-6510-485d-9158-e9fad06d0c49"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://catalogue-api.meritincentives.com/media/products/images/WalmartUS_GC.png',
          alt: '',
        }}
        title={'Walmart'}
        productType={'gift-card'}
      />,
      <GiftCardTile
        id="aa0a5b4c-c501-49da-9e2a-18efb669afcf"
        key="aa0a5b4c-c501-49da-9e2a-18efb669afcf"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://app.giftango.com/GPCGraphics/Starbucks_NoDenom_DIG_CR80_092120_300x190_RGB.png',
          alt: '',
        }}
        title={'Starbucks'}
        productType={'gift-card'}
      />,
      <GiftCardTile
        id="26bcbf5c-7032-4246-a52f-71e7d4c632b1"
        key="26bcbf5c-7032-4246-a52f-71e7d4c632b1"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://app.giftango.com/GPCGraphics/CIR_000035_00.png',
          alt: '',
        }}
        title={'Home Depot'}
        productType={'gift-card'}
      />,
      <FrequentTravelerTile
        id="8fae6eed-555c-4336-9391-7057354f46ff"
        key="8fae6eed-555c-4336-9391-7057354f46ff"
        backgroundColor={tileBackgroundColor}
        readyProduct={() => setHasProducts(true)}
        image={{
          url: 'https://kaligo.imgix.net/logos/ACCORLECLUB.en.png',
          alt: '',
        }}
        title={'ALL – Accor Live Limitless'}
        productType={'points-transfer'}
      />,
    ];
  }
  return (
    <div style={{ backgroundColor }}>
      <Container className={cn('py-10', { hidden: !hasProducts })}>
        <h2 className="text-heading-2">
          {user &&
          (user.sub === '9ac45ec3-509f-4a7c-8d63-b19079c3b823' ||
            user.sub === 'e1fafe26-1b66-4c82-9828-8ddd7ae7d107')
            ? t('Recommended for you')
            : title}
        </h2>
        <div
          data-testid="featured-products"
          className={cn(
            'mt-6 lg:mt-8',
            'grid grid-cols-1 gap-4 lg:gap-8',
            'lg:grid-cols-[repeat(auto-fit,_minmax(0,_1fr))]',
          )}
        >
          {componentsWithDemo}
        </div>
      </Container>
    </div>
  );
}

function CardInfo(props: CardInfoProps) {
  const { t } = useTranslation();
  const { heading, subheading, points, image, imageAlt, backgroundColor } =
    props;
  const pointsFormatter = usePointsFormatter();

  return (
    <div
      style={{ backgroundColor }}
      className={cn(
        'flex h-full flex-col overflow-hidden rounded border border-neutral-400 hover:border-black',
      )}
    >
      <img
        aria-hidden
        className={
          'mx8 mx-auto my-6 h-[160px] w-[calc(100%_-_2rem)] shrink-0 object-contain'
        }
        src={image}
        alt={imageAlt ?? ''}
        height={160}
        width={160}
      />
      <div
        className={cn(
          'flex w-full flex-col justify-center border-t border-t-neutral-400 px-6 py-6',
        )}
      >
        <h3 className={'mb-2 tracking-tight text-neutral-600'}>{heading}</h3>
        <p className={'font-bold text-black'}>
          {subheading && t(`${subheading}`)}
          {points && t(`${pointsFormatter(points)}`)}
        </p>
      </div>
    </div>
  );
}

function SkeletonTile(props: { backgroundColor: string }) {
  const { backgroundColor } = props;
  return (
    <div
      style={{ backgroundColor }}
      className={cn(
        'flex h-full flex-col overflow-hidden rounded border-neutral-300',
      )}
    >
      <div
        className={
          'mx-auto my-6 h-[160px] w-[calc(100%_-_2rem)] shrink-0 animate-pulse rounded bg-neutral-300'
        }
      ></div>

      <div
        className={
          'flex w-full flex-col justify-center border-t border-t-neutral-400 px-6 py-6'
        }
      >
        <div className="mb-2 h-[24px] w-1/2 flex-grow animate-pulse rounded bg-neutral-300"></div>
        <div className="mb-2 h-[24px] w-1/2 flex-grow animate-pulse rounded bg-neutral-300"></div>
        <div className="mb-2 h-[24px] w-1/2 flex-grow animate-pulse rounded"></div>
        <div className="w-2/32/3 h-[24px] flex-grow animate-pulse rounded bg-neutral-300"></div>
      </div>
    </div>
  );
}

// this is unused -> might be used later in the future
function GiftCardTile(props: FeaturedProductProps) {
  const { id, backgroundColor, readyProduct } = props;
  const { giftCard, isLoading, isError } = useGiftCardById(id);

  useEffect(() => {
    if (!isLoading && giftCard) {
      readyProduct();
    }
  }, [isLoading, giftCard]);

  if (isError) {
    return null;
  }

  return (
    <>
      {!isLoading && giftCard ? (
        <RcLink href={`/products/gift-cards/${id}`} className="rounded">
          <CardInfo
            backgroundColor={backgroundColor}
            image={giftCard.logoUrl}
            heading={giftCard.name}
            points={giftCard.denominations[0].price}
          ></CardInfo>
        </RcLink>
      ) : (
        <SkeletonTile backgroundColor={backgroundColor} />
      )}
    </>
  );
}

function FrequentTravelerTile(props: FeaturedProductProps) {
  const { id, backgroundColor, readyProduct } = props;
  const { loyaltyProgram, isFetched, isError } = useLoyaltyProgramByTypeAndId({
    id,
    loyaltyProgramType: loyaltyProgramTypeSchema.enum.points_transfer,
  });

  const link = `/products/frequent-traveler-programs/${id}`;
  const imageUrl = isFrequentTravelerLoyaltyProgram(loyaltyProgram)
    ? getCapabilityForLoyaltyProgramType(
        loyaltyProgramTypeSchema.enum.points_transfer,
        loyaltyProgram.capabilities,
      ).logoUrl
    : '';

  useEffect(() => {
    if (isFetched && loyaltyProgram) {
      readyProduct();
    }
  }, [isFetched, loyaltyProgram]);

  if (isError) {
    return null;
  }

  return isFetched && loyaltyProgram ? (
    <RcLink href={`${link}`} className="rounded">
      <CardInfo
        backgroundColor={backgroundColor}
        image={imageUrl}
        heading={loyaltyProgram.name}
        points={loyaltyProgram.transferRate.minimumTransfer}
      ></CardInfo>
    </RcLink>
  ) : (
    <SkeletonTile backgroundColor={backgroundColor} />
  );
}

function CashbackTile(props: FeaturedProductProps) {
  const { id, backgroundColor, readyProduct } = props;
  const { loyaltyProgram, isFetched, isError } = useLoyaltyProgramByTypeAndId({
    id,
    loyaltyProgramType: loyaltyProgramTypeSchema.enum.cashback,
  });

  const link = `/products/cashback/${id}`;
  const imageUrl = isCashbackLoyaltyProgram(loyaltyProgram)
    ? getCapabilityForLoyaltyProgramType(
        loyaltyProgramTypeSchema.enum.cashback,
        loyaltyProgram.capabilities,
      ).logoUrl
    : '';

  useEffect(() => {
    if (isFetched && loyaltyProgram) {
      readyProduct();
    }
  }, [isFetched, loyaltyProgram]);

  if (isError) {
    return null;
  }

  return isFetched && loyaltyProgram ? (
    <RcLink href={`${link}`} className="rounded">
      <CardInfo
        backgroundColor={backgroundColor}
        image={imageUrl}
        heading={loyaltyProgram.name}
        points={loyaltyProgram.transferRate.minimumTransfer}
      ></CardInfo>
    </RcLink>
  ) : (
    <SkeletonTile backgroundColor={backgroundColor} />
  );
}

function CarbonTile(props: FeaturedProductProps) {
  const { id, backgroundColor, readyProduct } = props;
  const { loyaltyProgram, isFetched, isError } = useLoyaltyProgramByTypeAndId({
    id,
    loyaltyProgramType: loyaltyProgramTypeSchema.enum.sustainability,
  });

  const link = `/products/sustainability/${id}`;
  const imageUrl = isSustainabilityLoyaltyProgram(loyaltyProgram)
    ? getCapabilityForLoyaltyProgramType(
        loyaltyProgramTypeSchema.enum.sustainability,
        loyaltyProgram.capabilities,
      ).logoUrl
    : '';

  useEffect(() => {
    if (isFetched && loyaltyProgram) {
      readyProduct();
    }
  }, [isFetched, loyaltyProgram]);

  if (isError) {
    return null;
  }

  return isFetched && loyaltyProgram ? (
    <Link href={`${link}`}>
      <CardInfo
        backgroundColor={backgroundColor}
        image={imageUrl}
        heading={loyaltyProgram.name}
        points={loyaltyProgram.transferRate.minimumTransfer}
      ></CardInfo>
    </Link>
  ) : (
    <SkeletonTile backgroundColor={backgroundColor} />
  );
}

function CryptoTile(props: FeaturedProductProps) {
  const { id, backgroundColor, readyProduct } = props;
  const { loyaltyProgram, isFetched, isError } = useLoyaltyProgramByTypeAndId({
    id,
    loyaltyProgramType: loyaltyProgramTypeSchema.enum.crypto,
  });

  const link = `/products/crypto/${id}`;
  const imageUrl = isCryptoLoyaltyProgram(loyaltyProgram)
    ? getCapabilityForLoyaltyProgramType(
        loyaltyProgramTypeSchema.enum.crypto,
        loyaltyProgram.capabilities,
      ).logoUrl
    : '';

  useEffect(() => {
    if (isFetched && loyaltyProgram) {
      readyProduct();
    }
  }, [isFetched, loyaltyProgram]);

  if (isError) {
    return null;
  }

  return isFetched && loyaltyProgram ? (
    <RcLink href={`${link}`} className="rounded">
      <CardInfo
        backgroundColor={backgroundColor}
        image={imageUrl}
        heading={loyaltyProgram.name}
        points={loyaltyProgram.transferRate.minimumTransfer}
      ></CardInfo>
    </RcLink>
  ) : (
    <SkeletonTile backgroundColor={backgroundColor} />
  );
}

function OfferTile(props: FeaturedProductProps) {
  const { id, backgroundColor, readyProduct, image } = props;

  const { offer, isFetched, isError } = useOfferById({
    id,
    offerType: OfferTypeFilter.All,
  });

  const link = `/products/offers/${id}`;

  useEffect(() => {
    if (isFetched && offer) {
      readyProduct();
    }
  }, [isFetched, offer]);

  if (isError) {
    return null;
  }

  return isFetched && offer ? (
    <RcLink href={`${link}`} className="rounded">
      <CardInfo
        backgroundColor={backgroundColor}
        image={image.url}
        heading={offer.brand}
        subheading={offer.title}
      ></CardInfo>
    </RcLink>
  ) : (
    <SkeletonTile backgroundColor={backgroundColor} />
  );
}

function UberTile(props: FeaturedProductProps) {
  const { backgroundColor, readyProduct } = props;

  const { uberLoyaltyProgramId, uberLoyaltyProgram } = useUberCredit({
    enabled: true,
  });

  useEffect(() => {
    if (uberLoyaltyProgramId) {
      readyProduct();
    }
  }, [uberLoyaltyProgramId]);

  if (!uberLoyaltyProgramId) {
    return null;
  }

  const link = `/products/uber-credit/${uberLoyaltyProgramId}`;

  const imageUrl =
    getCapabilityForLoyaltyProgramType(
      loyaltyProgramTypeSchema.enum.voucher,
      uberLoyaltyProgram.capabilities,
    ).logoUrl ?? '';

  return uberLoyaltyProgram ? (
    <RcLink href={`${link}`} className="rounded">
      <CardInfo
        backgroundColor={backgroundColor}
        image={imageUrl}
        heading={uberLoyaltyProgram.name}
        points={uberLoyaltyProgram.transferRate.minimumTransfer}
      ></CardInfo>
    </RcLink>
  ) : (
    <SkeletonTile backgroundColor={backgroundColor} />
  );
}
