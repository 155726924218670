import ListingProductSkeleton from '@/components/listing-product-skeleton';
import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { useTranslation } from '@/translation/translation';

export function RedeemAgainBannerSkeleton() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6 container-responsive">
      <div className="flex items-center justify-start gap-4">
        <p className="text-heading-2">{t('Redeem again')}</p>
        <RcLink
          href={'/rewards-summary/my-rewards'}
          variant={RcLinkVariant.SECONDARY}
          className="text-secondary"
        >
          {t('View all redemptions')}
        </RcLink>
      </div>

      <div className="grid grid-cols-3 gap-6">
        {[1, 2, 3].map((item) => (
          <ListingProductSkeleton
            key={item}
            className="h-[72px] overflow-hidden border-0"
          />
        ))}
      </div>
    </div>
  );
}
